import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { HttpStatusCode } from 'axios';

// components
import 'components/NotificationsView/NotificationsList/NotificationsList.scss';
import NotificationCard from 'components/NotificationsView/NotificationsList/NotificationCard/NotificationCard';
import Loader from 'components/Loader/Loader';
import { NotificationsTab } from 'components/NotificationsView/NotificationsView';
// services
import AppAxiosService from 'services/appAxios.service';
// models
import {
  StellioAlert,
  StellioEntityType,
  StellioEvent,
} from 'models/stellio.model';
// store
import { useAppSelector } from 'store/hooks';


interface Props {
  setTab: React.Dispatch<React.SetStateAction<NotificationsTab>>;
  onEdit: (event: StellioEvent) => void;
  onClearNotificationToEdit: () => void;
  tabValue: NotificationsTab;
}

const NotificationsList = ({
  setTab,
  tabValue,
  onEdit,
  onClearNotificationToEdit,
}: Props) => {
  const { t } = useTranslation();
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [alerts, setAlerts] = useState<StellioAlert[]>([]);
  const [events, setEvents] = useState<StellioEvent[]>([]);
  const stellioAxios = useMemo(() => new AppAxiosService(), []);

  const initNotifications = async () => {
    if (!userSub) return;

    try {
      setIsInitializing(true);

      const alertsPromise = stellioAxios.getEntityByType<StellioAlert>(
        StellioEntityType.ALERT
      );
      const eventsPromise = stellioAxios.getEntityByType<StellioEvent>(
        StellioEntityType.EVENT
      );
      const [alertsResponse, eventsReponse] = await Promise.all([
        alertsPromise,
        eventsPromise,
      ]);

      if (
        alertsResponse.status === HttpStatusCode.Ok &&
        eventsReponse.status === HttpStatusCode.Ok
      ) {
        const checkIsUserData = (entity: any) => entity.id.includes(userSub);
        setAlerts(alertsResponse.data.filter(checkIsUserData));
        setEvents(eventsReponse.data.filter(checkIsUserData));
      }

      setIsInitializing(false);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (tabValue === NotificationsTab.MY_NOTIFICATIONS) {
      initNotifications();
      onClearNotificationToEdit();
    }
  }, [tabValue]);

  const handleOnDelete = async (eventId: string) => {
    const linkedAlert = alerts.find(
      (alert) => alert.hasSubject.object === eventId
    );
    if (!linkedAlert) return;

    try {
      setIsDeleting(true);
      const deleteAlertPromise = stellioAxios.deleteEntityById(linkedAlert.id);
      const deleteEventPromise = stellioAxios.deleteEntityById(eventId);

      const [deleteAlertReponse, deleteEventResponse] = await Promise.all([
        deleteAlertPromise,
        deleteEventPromise,
      ]);

      const isAlertDeleted =
        deleteAlertReponse.status === HttpStatusCode.NoContent;
      const isEventDeleted =
        deleteEventResponse.status === HttpStatusCode.NoContent;
     
      if (isAlertDeleted && isEventDeleted) {
        initNotifications();
      }
    } catch (error) {
      console.warn(error);
    }

    setIsDeleting(false);
  };

  const handleOnEdit = (eventId: string) => {
    const event = events.find((event) => event.id === eventId);
    if (!event) return;

    onEdit(event);
    setTab(1);
  };

  return (
    <div id="notifs-list-container">
      {isInitializing && <Loader />}

      {!isInitializing &&
        events.length > 0 &&
        events.map((event) => {
          return (
            <NotificationCard
              key={event.id}
              event={event}
              onDelete={handleOnDelete}
              onEdit={handleOnEdit}
              isDeleting={isDeleting}
            />
          );
        })}

      {!isInitializing && events.length === 0 && (
        <div className="ec-empty-content">
          <div className="text-16-normal text">{t(`notifications.empty`)}</div>
          <div className="text-16-normal text">
            {t(`notifications.configureNotif`)}
          </div>
          <div className="btn-container">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              aria-label={t('ecogesture.emptyList.btn1')}
              onClick={() => setTab(1)}
            >
              {t('notifications.newNotif')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
